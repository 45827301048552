
let metaTags = {
    "/": {
      title: "ASTRNT – Transforming Hiring and Admissions",
      description: "ASTRNT transforms hiring, admissions, and talent management with science-based assessments and seamless workflow management for employers and universities.",
    },
    "/candidate-support": {
      title: "Candidate Support - ASTRNT",
      description: "Need help with your job application? Visit ASTRNT's Candidate Support page for FAQs, guidance, and recruitment assistance.",
    },
    "/enter-code": {
        title: "Enter Code - ASTRNT",
        description: "Simply enter your unique code on ASTRNT to easily access your job application or assessment, and start your journey toward success with no hassle today.",
      },
    "/register": {
        title: "Register - ASTRNT",
        description: "Sign up today to streamline your hiring or admissions process with ASTRNT and experience a faster, more efficient solution tailored to your needs.",
    },
    "/solution/talent-acquisition": {
        title: "Talent Acquisition Solutions - ASTRNT",
        description: "Streamline your hiring with ASTRNT by quickly matching talent across blue-collar, professional, and graduate roles to transform your process.",
    },
    "/request-demo": {
        title: "Request a Demo - ASTRNT",
        description: "Request a free demo today and discover how ASTRNT can transform your hiring and admissions processes with faster, more efficient solutions.",
    },
    "/solution/university-admission": {
        title: "University Admission Solutions - ASTRNT",
        description: "Simplify student admissions with ASTRNT, designed to help you efficiently find top talent and optimize every step of the admissions process.",
    },
    "/solution/talent-management": {
        title: "Talent Management Solutions - ASTRNT",
        description: "Reimagine talent management with NextGen Assessment Centers. Simplify hiring using asynchronous technology to save time, reduce costs, and boost efficiency.",
    },
    "/privacypolicy": {
        title: "Privacy Policy - ASTRNT",
        description: "Read ASTRNT’s privacy policy to understand how we protect your data, including how it's collected, used, and securely stored to ensure your privacy.",
    },
    "/termsofservice": {
        title: "Terms of Service - ASTRNT",
        description: "Check out ASTRNT’s terms of service to get familiar with the rules and guidelines for using our platform and services, ensuring a seamless experience.",
    },
    "/plans": {
        title: "Pricing Plans - ASTRNT",
        description: "Explore ASTRNT’s pricing plans for recruitment or admissions, including free options, and find the perfect solution tailored to your needs and budget.",
    },
    "/how-it-works": {
        title: "How ASTRNT Works - ASTRNT",
        description: "Learn how ASTRNT’s platform simplifies recruitment and admissions processes. Discover how to use it and streamline your workflow with our easy-to-follow guide.",
    },
    "/partnership": {
        title: "Partnership Opportunities - ASTRNT",
        description: "Collaborate with ASTRNT to transform hiring and admissions. Explore partnership opportunities and grow together.",
    },
    "/about": {
        title: "About Us - ASTRNT",
        description: "Discover ASTRNT's mission, vision, and the talented team behind our platform, transforming recruitment and admissions with innovative solutions and technology.",
    },

  };

if (window.location.hostname.match('test.astrnt.id') || window.location.hostname.match('astrnt.id')) {
	
    metaTags = {
        "/": {
          title: "ASTRNT – Mengubah Proses Rekrutmen dan Penerimaan Mahasiswa ",
          description: "ASTRNT optimalkan rekrutmen, penerimaan mahasiswa, dan manajemen talenta dengan asesmen berbasis sains serta alur kerja efisien untuk perusahaan dan universitas.",
        },
        "/candidate-support": {
          title: "Dukungan untuk Kandidat - ASTRNT",
          description: "Butuh bantuan melamar kerja? Kunjungi Dukungan Kandidat ASTRNT untuk FAQ, panduan, dan bantuan rekrutmen. ",
        },
        "/enter-code": {
            title: "Masukkan Kode - ASTRNT",
            description: "Masukkan kode unik di ASTRNT untuk mengakses lamaran atau asesmen kerja dan mulai perjalanan Anda hari ini.",
          },
        "/register": {
            title: "Daftar - ASTRNT",
            description: "Daftar sekarang untuk permudah proses rekrutmen atau penerimaan mahasiswa dengan ASTRNT, platform terbaik yang memanfaatkan teknologi untuk hasil lebih cepat.",
        },
        "/solution/talent-acquisition": {
            title: "Solusi Akuisisi Talenta - ASTRNT",
            description: "Permudah rekrutmen dengan ASTRNT, cocokkan talenta untuk posisi kerah biru, profesional, dan lulusan untuk transformasi proses Anda.",
        },
        "/request-demo": {
            title: "Demo - ASTRNT",
            description: "Minta demo gratis hari ini dan lihat bagaimana ASTRNT mengubah proses rekrutmen dan penerimaan mahasiswa dengan solusi teknologi terbaru yang efisien.",
        },
        "/solution/university-admission": {
            title: "Solusi Penerimaan Universitas - ASTRNT",
            description: "Permudah penerimaan mahasiswa dengan solusi ASTRNT, membantu Anda temukan talenta terbaik secara efisien dan optimalkan proses seleksi.",
        },
        "/solution/talent-management": {
            title: "Solusi Manajemen Talenta - ASTRNT",
            description: "Ubah manajemen talenta dengan NextGen Assessment Centers. Permudah rekrutmen dengan teknologi asinkron untuk hemat waktu, biaya, dan tingkatkan efisiensi.",
        },
        "/privacypolicy": {
            title: "Kebijakan Privasi - ASTRNT",
            description: "Baca kebijakan privasi ASTRNT untuk memahami bagaimana kami melindungi data Anda, termasuk pengumpulan, penggunaan, dan keamanan.",
        },
        "/termsofservice": {
            title: "Syarat dan Ketentuan - ASTRNT",
            description: "Tinjau syarat dan ketentuan ASTRNT untuk memahami aturan penggunaan platform dan layanan kami, serta cara memaksimalkan fitur untuk rekrutmen dan penerimaan mahasiwa.",
        },
        "/plans": {
            title: "Harga - ASTRNT",
            description: "Cek harga paket ASTRNT untuk rekrutmen atau penerimaan mahasiswa, ada yang gratis juga! Temukan solusi yang pas buat kebutuhan kamu di sini.",
        },
        "/how-it-works": {
            title: "Cara Kerja ASTRNT - ASTRNT",
            description: "Pelajari bagaimana ASTRNT mempermudah proses rekrutmen dan penerimaan mahasiswa dengan teknologi modern untuk hasil yang lebih cepat, efisien, dan terpercaya di sini.",
        },
        "/partnership": {
            title: "Peluang Kemitraan - ASTRNT",
            description: "Bergabung dengan ASTRNT untuk transformasi rekrutmen dan penerimaan mahasiswa. Temukan peluang kemitraan dan berkembang bersama.",
        },
        "/about": {
            title: "Tentang Kami - ASTRNT",
            description: "Temukan misi, visi, dan tim di balik ASTRNT, platform unggulan yang mentransformasi rekrutmen dan penerimaan dengan solusi teknologi terkini.",
        },
    
      };
}
  
  export default metaTags;